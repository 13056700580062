(function($){

    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();

        if (scroll >= 500) {
            $(".page-video-popup").removeClass("dn");
        } else {
            $(".page-video-popup").addClass("dn");
        }
    });
})(jQuery);

new WOW().init();